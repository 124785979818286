import React from 'react'
import HeroSection from '../../../shared/Rewrite/BrandPages/HeroSection'
import { scamData } from './consts'
import BrandSection from '../../../shared/BrandPages/BrandSection'
import Partners from './Partners'
import TrustReasons from './TrustReasons'
import Accordion from 'src/components/shared/FAQ/Accordion'
import ColumnToRowSection from '../../../shared/BrandPages/ColumnToRowSection'
import RecommendedReads from '../../../shared/BrandPages/RecommendedReads'
import BottomSection from '../../../shared/BrandPages/BottomSection'
import Section from '../../../shared/BrandPages/Section'
import { FAQPageJsonLd } from 'next-seo'
import Content from '../../../shared/BrandPages/Content'
import PrimaryButton from 'src/components/shared/PrimaryButton'
import CustomJsonLd from '../../../shared/CustomJsonLd'
import PropTypes from 'prop-types'
import styles from './index.module.scss'
import { documentToHtmlString } from '@contentful/rich-text-html-renderer'
import { jsonLdData } from '../../../../common/constants/jsonLd'
import LazyLoad from 'react-lazyload'
import VideoPlayer from 'src/components/shared/VideoPlayer'
import WithSeoOverrides from 'src/components/shared/WithSeoOverride'
// import { BannerPositions } from '../../../shared/Banner'
// import dynamic from 'next/dynamic'
// import useDeviceDetect from '../../../../common/hooks/useDeviceDetect'

// const Banner = dynamic(() => import('../../../shared/Banner'))

const ScamPage = ({ banner }) => {
  const {
    heroSectionData,
    brandDataPartners,
    partners,
    clientTestimonialsData,
    brandDataTrustReasons,
    trustReasons,
    brandDataTransparencyProgram,
    transparencyProgramFaq,
    brandDataFreedomPromises,
    freedomPromises,
    faqs,
    recommendedReads,
    bottomSectionData,
    seo,
  } = scamData

  const FAQJsonLdData = faqs.map((item) => ({
    questionName: item.title,
    acceptedAnswerText: documentToHtmlString(item.content).replace(/"/g, "'"),
  }))

  return (
    <Content>
      <FAQPageJsonLd mainEntity={FAQJsonLdData} />
      <CustomJsonLd
        id={'webPage'}
        data={jsonLdData(seo?.title, seo?.description, '/scam/')}
      />
      <WithSeoOverrides {...seo} />
      <Section classNames="pt-0 pb-0 lg:pl-4 xl:pl-0">
        <HeroSection
          {...heroSectionData}
          contentClassName={styles.contentClassName}
          heroContainerClassName={styles.heroClassName}
          overlayShadowClassName={styles.overlayShadow}
        />
      </Section>
      {/*{Object.keys(banner).length &&*/}
      {/*banner.position === BannerPositions.UnderHeroSection ? (*/}
      {/*  <Section>*/}
      {/*    <Banner {...banner} />*/}
      {/*  </Section>*/}
      {/*) : null}*/}
      <Section classNames={`py-14 bg-blue-base-white`}>
        <BrandSection
          {...brandDataPartners}
          childClassName="pt-8 lg:pt-14 order-2"
          wrapperClassName="mt-8 lg:mt-0"
        >
          <Partners data={partners} />
        </BrandSection>
      </Section>
      <Section>
        <BrandSection {...brandDataTrustReasons}>
          <TrustReasons data={trustReasons} />
        </BrandSection>
      </Section>
      <Section classNames="bg-blue-base-white py-8 lg:py-12">
        <div className="gap-x-primary padding-x-primary grid grid-cols-4 lg:grid-cols-12">
          <div className="col-span-full lg:col-span-8 lg:col-start-3">
            <BrandSection
              {...clientTestimonialsData}
              descriptionClassname="order-3 pb-3 lg:pb-0"
              childClassName="order-2 lg:order-last pt-4 lg:pt-8 flex items-center justify-center"
            >
              <LazyLoad>
                <div className="object-top pb-8 lg:pb-0">
                  <VideoPlayer
                    thumbnail="rounded-full h-12 w-12 md:h-20 md:w-20 bg-gray-230/20"
                    url="https://www.youtube.com/watch?v=f36VuLpX1K0"
                    className="h-52 w-84 md:h-72 md:w-130 lg:h-96 lg:w-200 xl:h-112"
                    light={'/next-assets/brand-pages/scam/video-image.jpg'}
                  />
                </div>
              </LazyLoad>
            </BrandSection>
          </div>
        </div>
      </Section>

      <Section classNames={'pb-6 pt-8 lg:py-12'}>
        <BrandSection
          {...brandDataTransparencyProgram}
          primaryBtnClassName="px-9 bg-red-950"
          childClassName="order-3"
        >
          <Accordion
            questionClassName="font-medium lg:pt-5.4"
            answerClassName="pl-4 pr-12 text-15 lg:pl-0"
            items={transparencyProgramFaq}
            isRichText={false}
          />
        </BrandSection>
      </Section>
      <Section classNames="bg-blue-base-white py-8 lg:py-12">
        <BrandSection {...brandDataFreedomPromises} childClassName="order-3">
          <ColumnToRowSection
            data={freedomPromises}
            isMobileSlider={true}
            sliderName="freedom-promises"
          />
        </BrandSection>
      </Section>
      {/*{(Object.keys(banner).length &&*/}
      {/*  banner.position === BannerPositions.Middle) ||*/}
      {/*(banner.position === BannerPositions.RightSide && !isDesktop) ? (*/}
      {/*  <Section>*/}
      {/*    <Banner {...banner} />*/}
      {/*  </Section>*/}
      {/*) : null}*/}
      <Section classNames={'py-8 lg:pt-12'}>
        <p className="p-4 text-center text-2.5xl font-bold leading-10 text-black-base lg:px-0 lg:py-5 lg:text-37 lg:leading-52">
          Freedom Debt Relief FAQs
        </p>
        <Accordion
          questionClassName="font-medium lg:pt-5.4"
          answerClassName="pl-4 pr-12 text-15 lg:pl-0"
          items={faqs}
        />
        <div className="order-4 flex justify-center pt-5 ">
          <PrimaryButton
            title="Read more"
            href="/faq/"
            className="bg-blue-base px-8 py-2.5 text-white"
          />
        </div>
      </Section>
      <Section classNames="bg-blue-base-white py-8 lg:pb-16 lg:pt-8">
        <RecommendedReads data={recommendedReads} />
      </Section>
      {/*{Object.keys(banner).length &&*/}
      {/*banner.position === BannerPositions.AboveFooter ? (*/}
      {/*  <Section>*/}
      {/*    <Banner {...banner} />*/}
      {/*  </Section>*/}
      {/*) : null}*/}
      <Section classNames="pt-0 pb-0">
        <BottomSection
          {...bottomSectionData}
          className="max-w-[600px]"
          isDark={true}
        />
      </Section>
    </Content>
  )
}

export default ScamPage

ScamPage.defaultProps = {
  banner: {},
}
ScamPage.propTypes = {
  banner: PropTypes.object,
}
