import React from 'react'
import Layout from '../src/components/layouts'
import ScamPage from '../src/components/pages/BrandPages/Scam'
// import BannerRepository from '../src/repositories/BannerRepository'
// import dynamic from 'next/dynamic'
// import useDeviceDetect from '../src/common/hooks/useDeviceDetect'
// import { BannerPositions } from '../src/components/shared/Banner'
// import Section from '../src/components/shared/BrandPages/Section'

// const Banner = dynamic(() => import('../src/components/shared/Banner'))

export async function getServerSideProps() {
  // let banner = {}

  // try {
  //   banner = await BannerRepository.getInstance().getByPageSpecified('Scam')
  // } catch (error) {
  //   logger.error('Scam page: failed to get banner data', { error })
  // }

  return {
    props: {
      // banner: banner || {},
    },
  }
}

const Scam = () => {
  // const { isDesktop } = useDeviceDetect()

  // const rightBanner = useMemo(() => {
  //   return banner?.position === BannerPositions.RightSide && isDesktop
  // }, [banner, isDesktop])

  return (
    <Layout
      crawlable={true}
      isBrandPage={true}
      footerClassName="mb-5 lg:mb-0"
      dashboardClass="bg-blue-300 text-white"
    >
      {/*<div className={`${rightBanner ? 'grid grid-cols-12' : ''}`}>*/}
      {/*  <div className={`${rightBanner ? 'show col-span-9' : ''}`}>*/}
      <ScamPage />
      {/*</div>*/}
      {/*<div className={`${rightBanner ? 'show col-span-3 mx-11' : 'hidden'}`}>*/}
      {/*  <Section>*/}
      {/*    <Banner {...banner} align="vertical" />*/}
      {/*  </Section>*/}
      {/*</div>*/}
      {/*</div>*/}
    </Layout>
  )
}

export default Scam
