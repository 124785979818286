import { APPLY_FDR_URL, APP_BASE_PATH } from '../../../../common/constants'
import { recommendedReadsData } from '../../consts'

const { howItWorks, whyWereBetter, testimonials } = recommendedReadsData

const TITLE = 'Legitimacy vs Scam | Freedom Debt Relief'
const DESCRIPTION =
  'Freedom Debt Relief is not a scam. We are a legitimate debt relief company that gets real results for our clients and pride ourselves on our integrity.'
const URL = `${APP_BASE_PATH()}/scam/`

export const scamData = {
  seo: {
    title: TITLE,
    description: DESCRIPTION,
    canonical: URL,
    openGraph: {
      title: TITLE,
      description: DESCRIPTION,
      url: URL,
      type: 'article',
      locale: 'en_US',
      article: {
        modifiedTime: '2020-04-07T23:27:48+00:00',
        authors: ['https://www.facebook.com/freedomdebtrelief'],
      },
      images: [
        {
          url: `${APP_BASE_PATH()}/next-assets/brand-pages/heros/scam.webp`,
        },
      ],
    },
  },
  heroSectionData: {
    title: 'Why our clients trust Freedom Debt Relief',
    id: 'scamHero',
    description: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'text',
          value: 'As a',
          marks: [],
          data: {},
        },
        {
          nodeType: 'hyperlink',
          data: {
            uri: '/about/',
          },
          content: [
            {
              nodeType: 'text',
              value: ' leader in debt resolution ',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'text',
          value:
            'for 22 years, we empower everyday people to move toward a better financial future.',
          marks: [],
          data: {},
        },
      ],
    },
    img: {
      src: '/next-assets/brand-pages/heros/scam.webp',
      alt: 'Freedom Debt Relief lobby',
    },
  },
  brandDataPartners: {
    tagline: 'Our recognition',
    title: 'An established leader in debt relief',
    description: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'hyperlink',
          data: {
            uri: '/why-were-better/',
          },
          content: [
            {
              nodeType: 'text',
              value: 'Freedom Debt Relief',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'text',
          value:
            ' is a founding member of the AADR and member of multiple organizations\n committed to holding legitimate debt relief companies to the highest standards.',
          marks: [],
          data: {},
        },
      ],
    },
    hasButton: false,
  },
  partners: [
    {
      src: '/next-assets/aadr-logo.png',
      alt: 'American Association for Debt Resolution',
      isWidget: false,
    },
    {
      src: '/next-assets/brand-pages/partners/bbb-desktop.png',
      alt: 'Better Business Bureau BBB rating A+',
      isWidget: true,
      widget: `<a href='https://www.bbb.org/us/ca/san-mateo/profile/debt-relief-services/freedom-debt-relief-llc-1116-65019' target='_blank' rel='nofollow'>
                    <img src='/next-assets/brand-pages/partners/bbb-scam.png' style='height: 100px' class='h-16 w-52 lg:mb-0' alt='Freedom Debt Relief LLC BBB Business Review' />
              </a>`,
    },
    {
      src: '/next-assets/brand-pages/partners/fhn.png',
      alt: 'financial health network',
      isWidget: false,
    },
    {
      src: '/next-assets/brand-pages/partners/iapda.png',
      alt: 'IAPDA certification',
      isWidget: false,
    },
  ],
  clientTestimonialsData: {
    tagline: 'Client testimonials',
    title: 'Real Stories from Real Clients',
    description: {
      nodeType: 'paragraph',
      content: [
        {
          nodeType: 'text',
          value:
            'From struggling with debt to living life again, the testimonials below show how our clients used\n Freedom Debt Relief to successfully reduce debt.',
          marks: [],
        },
      ],
    },
    hasLink: true,
    link: {
      name: 'Watch more stories',
      href: '/testimonials/',
      hasArrowIcon: true,
    },
    hasButton: false,
  },
  brandDataTrustReasons: {
    tagline: 'Reasons to trust Freedom',
    title: 'What to look out for:\n Freedom Debt Relief vs. scams',
    description: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'text',
          value:
            'Here are 4 major reasons why you can be confident Freedom Debt Relief is a leader\n you can trust, and 4 major signs of a scam.',
          marks: [],
          data: {},
        },
      ],
    },
    hasButton: false,
  },
  trustReasons: [
    {
      trust: {
        title: 'Freedom Debt Relief is here for you when you’re ready',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Freedom Debt Relief makes our program information openly available to you and our certified debt consultants are ready for your call. ',
              marks: [],
              data: {},
            },
          ],
        },
      },
      scam: {
        title: 'They contact you first',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'You could get a robocall or a recorded message claiming a company can wipe out all your debt.',
              marks: [],
              data: {},
            },
          ],
        },
      },
    },
    {
      trust: {
        title: 'Freedom Debt Relief doesn’t charge upfront fees',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'At Freedom Debt Relief, there are no upfront fees. We don’t charge a fee until we negotiate a settlement, you authorize it, and at least one payment has been made to your creditor.',
              marks: [],
              data: {},
            },
          ],
        },
      },
      scam: {
        title: 'They ask for fees upfront',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'This is the most obvious sign of a debt relief scam. Charging upfront fees to settle a debt is illegal, according to the FTC.',
              marks: [],
              data: {},
            },
          ],
        },
      },
    },
    {
      trust: {
        title: 'Freedom Debt Relief helps you handle creditors',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'We provide you with scripts to use, videos to watch, and 24/7 client services for additional support for your questions.',
              marks: [],
              data: {},
            },
          ],
        },
      },
      scam: {
        title: 'They tell you to stop talking to creditors',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'text',
              value:
                'Creditors will still call, and stopping all communication may make things worse. A reputable company leaves that decision to you.',
              marks: [],
              data: {},
            },
          ],
        },
      },
    },
    {
      trust: {
        title: 'Freedom Debt Relief provides easy access to client services',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'hyperlink',
              data: {
                uri: '/contact/',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'Contact ',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value:
                "our certified debt consultants and customer service agents by email or phone. If you're already a client, you can access our online client dashboard 24/7.",
              marks: [],
              data: {},
            },
          ],
        },
      },
      scam: {
        title: 'They have questionable contact info',
        description: {
          nodeType: 'paragraph',
          data: {},
          content: [
            {
              nodeType: 'hyperlink',
              data: {
                uri: '/learn/debt-relief/tax-debt-compromise-program-phone-call/',
              },
              content: [
                {
                  nodeType: 'text',
                  value: 'Scammers ',
                  marks: [],
                  data: {},
                },
              ],
            },
            {
              nodeType: 'text',
              value:
                'can fake phone numbers or hide behind websites. To confirm they’re a legitimate company, check the FTC and search the Better Business Bureau (BBB).',
              marks: [],
              data: {},
            },
          ],
        },
      },
    },
  ],
  brandDataTransparencyProgram: {
    tagline: 'Freedom Debt Relief',
    title: 'Transparency around our program:\nWhat to know',
    description: {
      nodeType: 'paragraph',
      content: [
        {
          nodeType: 'text',
          value:
            'If you’re working with a legitimate debt relief company like Freedom Debt Relief, you should know the truth about all\n aspects of the program before you enroll—not just the upsides. That’s why we’re transparent about things like:',
          marks: [],
        },
      ],
    },
    button: { name: 'Get started', href: APPLY_FDR_URL() },
    isLeadId: true,
  },
  transparencyProgramFaq: [
    {
      title: 'Fees',
      content: {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value:
              'We never collect a fee until a settlement agreement is reached, you approve the settlement, and a payment has been made for the settlement. ' +
              'The fee is based on your enrolled debt amount, state of residence, and other factors. We clearly explain our fee to clients before they enter our program, and our fee is set at enrollment, and never increases.',
            marks: [],
          },
        ],
      },
    },
    {
      title: 'Credit impact',
      content: {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value:
              'Depending on your existing credit score, in the debt settlement process you will likely see your credit score drop. However, a key factor of your financial health is your debt-to-income ratio. As debts are settled, your total debt should decrease, thereby improving your debt-to-income ratio.',
            marks: [],
          },
        ],
      },
    },
    {
      title: 'Program length & debt reduction',
      content: {
        nodeType: 'paragraph',
        content: [
          {
            nodeType: 'text',
            value:
              'The Freedom Debt Relief program takes an average of 24-48 months.* Over 60% of our clients get their first settlement within the first 3 months ' +
              'of enrollment. Since every client’s debt situation is different, no legitimate debt relief company can guarantee how much debt they can ' +
              'reduce for their clients—and beware of any company that does. However, because we have a long history of negotiating with many creditors,  we’re confident that ',
            marks: [],
          },
          {
            nodeType: 'text',
            value:
              'our program could significantly reduce the amount our clients owe.',
            marks: [{ type: 'bold' }],
          },
        ],
      },
    },
  ],
  brandDataFreedomPromises: {
    tagline: 'True to our mission',
    title: 'The Freedom Debt Relief Promise',
    description: {
      nodeType: 'paragraph',
      data: {},
      content: [
        {
          nodeType: 'text',
          value: 'Our reputation is built on the trust ',
          marks: [],
          data: {},
        },
        {
          nodeType: 'hyperlink',
          data: {
            uri: '/testimonials/',
          },
          content: [
            {
              nodeType: 'text',
              value: 'clients',
              marks: [],
              data: {},
            },
          ],
        },
        {
          nodeType: 'text',
          value:
            " place in us to help them succeed in getting rid of debt. To earn that trust,\n we're committed in the four ways below, putting you at the center of everything we do and making sure the Freedom\n Debt Relief program is recommended only when it is truly the best fit for your needs.",
          marks: [],
          data: {},
        },
      ],
    },
    hasButton: false,
  },
  freedomPromises: [
    {
      img: {
        src: '/next-assets/icons/free-consultation.svg',
        mobileSrc: '/next-assets/icons/free-consultation-mobile.svg',
        alt: 'Free Consultation',
      },
      tagline: 'You can make informed decisions',
      title: 'Free debt evaluation',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'We look at your financial situation closely and go over various options to resolve your debt, from',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/debt-solutions/bankruptcy/',
            },
            content: [
              {
                nodeType: 'text',
                value: ' bankruptcy ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value: 'to',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/debt-solutions/credit-counseling/',
            },
            content: [
              {
                nodeType: 'text',
                value: ' credit counseling ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value:
              'to personal loans, so you can choose if debt settlement is right for you.',
            marks: [],
            data: {},
          },
        ],
      },
    },
    {
      img: {
        src: '/next-assets/icons/free-assessment.svg',
        mobileSrc: '/next-assets/icons/free-assessment-mobile.svg',
        alt: 'Free assessment',
      },
      tagline: 'You’ll be set up for success',
      title: 'Personalized assessment',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'We look at hardship qualifiers such as job loss or unexpected expenses, and do a cash flow assessment to tailor your plan to what you can afford.',
            marks: [],
            data: {},
          },
        ],
      },
    },
    {
      img: {
        src: '/next-assets/icons/clear-terms.svg',
        mobileSrc: '/next-assets/icons/clear-terms-mobile.svg',
        alt: 'Clear terms',
      },
      tagline: 'You won’t be surprised',
      title: 'Clear terms',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value: 'Before enrolling, you’ll have important information about',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/how-it-works/',
            },
            content: [
              {
                nodeType: 'text',
                value: ' how the program works ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value:
              'so you’ll know about its credit impact, what to expect with collection calls, tax implications, and more.',
            marks: [],
            data: {},
          },
        ],
      },
    },
    {
      img: {
        src: '/next-assets/icons/program-guarantee.svg',
        mobileSrc: '/next-assets/icons/program-guarantee-mobile.svg',
        alt: 'Program guarantee',
      },
      tagline: 'You will get results',
      title: 'Program guarantee',
      description: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'If your total program settlement cost is more than the total amount of debt you enroll in our program, we’ll refund you the difference from our collected fees, up to 100%.**',
            marks: [],
            data: {},
          },
        ],
      },
    },
  ],
  faqs: [
    {
      title: 'Are debt settlement companies a good option?',
      content: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/debt-solutions/debt-settlement/',
            },
            content: [
              {
                nodeType: 'text',
                value: ' Debt settlement ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value:
              'is a good option for dealing with debt as you can resolve your debt for less than you owe and in\n       less time than',
            marks: [],
            data: {},
          },
          {
            nodeType: 'hyperlink',
            data: {
              uri: '/credit-card-debt/credit-card-minimum-payment/',
            },
            content: [
              {
                nodeType: 'text',
                value: ' making minimum payments. ',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'text',
            value:
              "But that doesn't mean all debt settlement companies are created equal.\n" +
              'Freedom Debt Relief has been around for 22 years and has negotiated over $18 billion in consumer debt, helping more than 1 million consumers get rid of debt.',
            marks: [],
            data: {},
          },
        ],
      },
    },
    {
      title: 'How much does debt relief cost?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Our fee ranges between 15% to 25% of enrolled debt (the amount of debt enrolled to be settled)–your rate may vary depending on your state of residency.',
                marks: [],
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value: 'In',
                marks: [],
                data: {},
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/how-it-works/',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' our program',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value:
                  ', clients pay no fee whatsoever to Freedom Debt Relief until a debt is negotiated, the settlement has been approved and the first payment has been made. You will know when a fee is charged because you will approve every settlement deal. Once we reach a settlement with a creditor, we immediately contact you for authorization. After you authorize athe settlement and a payment has been made to your creditor, the fee associated with the debt is processed. The total fee associated with the program is included in the monthly savings quoted to you by a debt consultant.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      title: 'How does debt relief affect my credit?',
      content: {
        nodeType: 'document',
        data: {},
        content: [
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'The impact a debt relief solution has to your credit score depends on the specific information found on your credit\n       report at the time of enrollment, and how the specific information found on your credit report changes while in the program.\n       Your',
                marks: [],
              },
              {
                nodeType: 'hyperlink',
                data: {
                  uri: '/credit-score/credit-report-vs-credit-score/',
                },
                content: [
                  {
                    nodeType: 'text',
                    value: ' credit report ',
                    marks: [],
                    data: {},
                  },
                ],
              },
              {
                nodeType: 'text',
                value:
                  'can be affected by the settlements you experienced while in the program (including how creditors report the\n       information to the CRAs) as well as your credit behavior while in the program. \n',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  ' The typical debt settlement client experiences a reduction in their credit score in the first six months of the program followed by a steady rehabilitation in their credit score thereafter.  ',
                marks: [{ type: 'bold' }],
                data: {},
              },
              {
                nodeType: 'text',
                value:
                  ' Results vary depending on the credit history and credit behavior, but in general, developing a history of paying bills on time, living within your means, and only applying for credit that you need are behaviors that can help your credit score recover over time.',
                marks: [],
                data: {},
              },
            ],
          },
          {
            nodeType: 'paragraph',
            data: {},
            content: [
              {
                nodeType: 'text',
                value:
                  'Credit scores are an integral part of one’s financial health.  However, it can be argued that indebtedness is a more foundational\n       component as indebtedness is a factor in credit scores.  The principal reduction that debt relief can provide can better position\n       a consumer to have a healthier credit score by improving their cash flow, enabling them to repay existing credit\n       obligations and to keep debt loads lower.  A debt relief program could help you resolve your debt faster and for less\n       (than making minimum payments). This can be a viable and more attractive option than bankruptcy.',
                marks: [],
                data: {},
              },
            ],
          },
        ],
      },
    },
    {
      title: 'How long does Freedom Debt Relief take?',
      content: {
        nodeType: 'paragraph',
        data: {},
        content: [
          {
            nodeType: 'text',
            value:
              'The Freedom Debt Relief program is unique to each client. The average enrollment period for Freedom Debt Relief customers\n              is 24-48 months.* The length of time that a client is enrolled depends on a lot of factors, like the amount of debt they\n              have and who their creditors are. The Freedom Debt relief team works with each client to build a program and schedule that\n              fits their specific needs.',
            marks: [],
            data: {},
          },
        ],
      },
    },
  ],
  recommendedReads: [howItWorks, whyWereBetter, testimonials],
  bottomSectionData: {
    title: `Discover what makes Freedom Debt Relief different`,
    list: ['No upfront fees', 'No loan required', 'No more doing it alone'],
    image: {
      src: '/next-assets/brand-pages/scam/section-cta-scams.png',
      alt: 'Freedom Debt Relief female client',
    },
  },
}
