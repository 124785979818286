import React from 'react'
import PropTypes from 'prop-types'
import Img from '../../../../../../../src/components/shared/Img'
import RichText from '../../../../../shared/RichText'
import styles from './index.module.scss'
import { generateElementId } from '../../../../../../common/helpers'

const TrustScamItem = ({ title, description, src, alt, className }) => {
  return (
    <div
      className={`flex flex-equal flex-col items-start justify-start lg:flex-row  ${className}`}
      id={generateElementId(title)}
    >
      <Img
        src={src}
        alt={alt}
        layout={'fixed'}
        width={28}
        height={28}
        className="mb-4 mr-0 mt-0 lg:mb-0 lg:mr-9 lg:mt-1"
        imgClassName={'max-w-none'}
      />
      <div>
        <h3 className="min-h-16 text-lg font-bold leading-7 lg:text-2xl lg:font-medium">
          {title}
        </h3>
        <RichText
          contentData={description}
          className={`${styles.description} mt-4 text-15 leading-170`}
        />
      </div>
    </div>
  )
}

TrustScamItem.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.string,
}

const TrustReasonItem = ({ item }) => {
  return (
    <div className="col-span-full mr-4  rounded border px-6 pt-10  lg:col-span-8 lg:col-start-3 lg:mx-0 lg:mb-9  lg:border-0 lg:p-0">
      <div className="flex flex-col justify-between lg:flex-row">
        <TrustScamItem
          title={item.trust.title}
          description={item.trust.description}
          src={'/next-assets/icons/right.svg'}
          alt={'Right sign'}
          className={
            'mb-8 mr-0 min-h-80 border-b md:min-h-52 lg:mr-9 lg:border-b-0'
          }
        />
        <TrustScamItem
          title={item.scam.title}
          description={item.scam.description}
          src={'/next-assets/icons/wrong.svg'}
          alt={'Wrong sign'}
          className={'min-h-80 md:min-h-52'}
        />
      </div>
    </div>
  )
}

export default TrustReasonItem

TrustReasonItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.object,
  }),
}
