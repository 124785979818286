import React from 'react'
import Img from '../../../../../../src/components/shared/Img'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import ReactHtmlParser from 'react-html-parser'

const Partners = ({ data }) => {
  return (
    <div
      className="w-content gap-x-primary col-span-full grid grid-cols-4 lg:grid-cols-12 "
      id="partners"
    >
      <div className="col-span-full lg:col-span-10 lg:col-start-2">
        <div className="flex flex-col items-center lg:flex-row lg:justify-around">
          {data.map((item, index) =>
            item.isWidget ? (
              <div className="mb-10 lg:mb-0" key={index}>
                {ReactHtmlParser(item.widget)}
              </div>
            ) : (
              <Img
                key={index}
                className={classNames(
                  { 'mb-10': index !== data.length - 1 },
                  'h-16 w-52 lg:mb-0'
                )}
                dataTestId={item.alt}
                src={item.src}
                alt={item.alt}
              />
            )
          )}
        </div>
      </div>
    </div>
  )
}

export default Partners

Partners.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
}
