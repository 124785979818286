import React from 'react'
import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import LazyLoad from 'react-lazyload'
import TrustReasonItem from './TrustReasonItem'
import useDeviceDetect from '../../../../../common/hooks/useDeviceDetect'
import { setDotsClassName } from '../../../../../common/helpers'

const SlickSlider = dynamic(() =>
  import('../../../../shared/BrandPages/SlickSlider')
)

const TrustReasonsComponent = ({ data }) =>
  data.map((item, index) => <TrustReasonItem item={item} key={index} />)

const TrustReasons = ({ data }) => {
  const { isMobile } = useDeviceDetect(true)

  const settings = {
    slidesToShow: 1.1,
    slidesToScroll: 1,
    speed: 100,
    dots: true,
    arrows: false,
    afterChange: (current) => {
      setDotsClassName(current, document, 'slider-trust-scam')
    },
  }

  return (
    <div
      className={`w-content border-gray-45 col-span-full mb-4 mt-12 grid grid-cols-4 gap-x-8 rounded px-4 lg:grid-cols-12 lg:border-0`}
      id="trust-reasons"
    >
      {!isMobile ? (
        <TrustReasonsComponent data={data} />
      ) : (
        <div className="col-span-full">
          <LazyLoad placeholder={<TrustReasonsComponent data={data} />}>
            <SlickSlider
              sliderSettings={settings}
              name={'trust-scam'}
              dotsClassName="pb-12"
            >
              {data.map((item, index) => (
                <TrustReasonItem item={item} key={index} />
              ))}
            </SlickSlider>
          </LazyLoad>
        </div>
      )}
    </div>
  )
}

export default TrustReasons

TrustReasons.proppTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      trust: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
      scam: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }),
    })
  ),
}
